"use client";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserPreferences } from "./UserPreferenceContext";

const ThemeMode = {
	AUTO: "auto",
	LIGHT: "light",
	DARK: "dark",
} as const;
type ThemeMode = (typeof ThemeMode)[keyof typeof ThemeMode];
export { ThemeMode };

interface ThemeContextProps {
	theme: ThemeMode;
	setTheme: (theme: ThemeMode) => void;
	effectiveTheme: ThemeMode;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { getPreference, setPreference } = useUserPreferences();

	const [theme, setThemeState] = useState<ThemeMode>(
		getPreference<ThemeMode>("theme", ThemeMode.AUTO)
	);

	const setTheme = (newTheme: ThemeMode) => {
		setThemeState(newTheme);
		setPreference<ThemeMode>("theme", newTheme);
	};

	const [effectiveTheme, setEffectiveTheme] = useState<ThemeMode>(
		ThemeMode.LIGHT
	);

	useEffect(() => {
		const root = document.documentElement;
		const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

		const getSystemTheme = () =>
			mediaQuery.matches ? ThemeMode.DARK : ThemeMode.LIGHT;

		const handleChange = () => {
			if (theme === ThemeMode.AUTO) {
				const systemTheme = getSystemTheme();
				root.setAttribute("data-theme", systemTheme);
				setEffectiveTheme(systemTheme);
			} else {
				root.setAttribute("data-theme", theme);
				setEffectiveTheme(theme);
			}
		};

		handleChange();
		mediaQuery.addEventListener("change", handleChange);

		return () => {
			mediaQuery.removeEventListener("change", handleChange);
		};
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, setTheme, effectiveTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};

export const useTheme = (): ThemeContextProps => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error("useTheme must be used within a ThemeProvider");
	}
	return context;
};
