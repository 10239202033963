"use client";
import React, { createContext, useContext } from "react";

interface UserPreferenceContextProps {
	getPreference: <T>(key: string, defaultValue: T) => T;
	setPreference: <T>(key: string, value: T) => void;
	removePreference: (key: string) => void;
}

const UserPreferenceContext = createContext<
	UserPreferenceContextProps | undefined
>(undefined);

export const UserPreferenceProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const getPreference = <T,>(key: string, defaultValue: T): T => {
		if (typeof window !== "undefined") {
			try {
				const savedValue = localStorage.getItem(key);
				return savedValue ? (JSON.parse(savedValue) as T) : defaultValue;
			} catch (error) {
				console.error(`Error parsing preference for key "${key}":`, error);
				localStorage.removeItem(key);
				return defaultValue;
			}
		}
		return defaultValue;
	};

	const setPreference = <T,>(key: string, value: T): void => {
		if (typeof window !== "undefined") {
			localStorage.setItem(key, JSON.stringify(value));
		}
	};

	const removePreference = (key: string): void => {
		if (typeof window !== "undefined") {
			localStorage.removeItem(key);
		}
	};

	return (
		<UserPreferenceContext.Provider
			value={{ getPreference, setPreference, removePreference }}
		>
			{children}
		</UserPreferenceContext.Provider>
	);
};

export const useUserPreferences = (): UserPreferenceContextProps => {
	const context = useContext(UserPreferenceContext);
	if (!context) {
		throw new Error(
			"useUserPreferences must be used within a UserPreferenceProvider"
		);
	}
	return context;
};
